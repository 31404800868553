import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { IoArrowForward } from "react-icons/io5";
import { RiUserSmileLine } from "react-icons/ri";
import { MdOutlineEmail } from "react-icons/md";
import { FaYoutube } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa6";
import { TbWorld } from "react-icons/tb";
import { RxHamburgerMenu } from "react-icons/rx";
// import mobpage1img from "../assets/images/landingpageImage/mobpage1.svg";

import style from "./landingpage.module.css";
import logo from "../assets/images/landingpageImage/Logo-colored.png";
import logo2 from "../assets/images/landingpageImage/logo2.png";
import emoji from "../assets/images/landingpageImage/emoji.png";

import mobpage2img from "../assets/images/landingpageImage/mobpage2.svg";
import icon1 from "../assets/images/landingpageImage/icon1.svg";
import icon2 from "../assets/images/landingpageImage/icon2.svg";
import icon3 from "../assets/images/landingpageImage/icon3.svg";
import icon4 from "../assets/images/landingpageImage/icon4.svg";

// services
import Utils from "../services/utils.service";
import { useNavigate } from "react-router-dom";
import { errorAlert, sucessAlert } from "../component/alert/CustomAlert";

function Landingpage() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  const [emailError, setEmailError] = useState("");
  const currentYear = new Date().getFullYear();

  const demoValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    message: Yup.string().required("Message is required"),
  });

  const earlyAccessValidationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (emailError) {
      setEmailError("");
    }
  };
  const [showbtnloder, setShowbtnloder] = useState(false);

  const handleDemoSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      setShowbtnloder(true);

      await Utils.addBrevoDemoBookList(
        values.email,
        values.name,
        values.message
      );
      sucessAlert("Successfully added to the demo booking list.");

      resetForm();
      setShowbtnloder(false);
    } catch (error) {
      console.error("Error adding to demo booking list:", error);
      errorAlert("There was an error. Please try again.");
    }
    setSubmitting(false);
  };

  const handleEarlyAccessSubmit = async (
    values,
    { setSubmitting, resetForm }
  ) => {
    try {
      console.log("values.email-->", values.email);
      await Utils.addBrevoEarlyAccessList(values.email);
      sucessAlert("Successfully added to early access list.");
      resetForm();
    } catch (error) {
      console.error("Error adding to early access list:", error);
      errorAlert("There was an error. Please try again.");
    }
    setSubmitting(false);
  };
  let navigate = useNavigate();
  const gotoToTermsPage = () => {
    navigate("/termsAndCondition");
  };

  return (
    <>
      <div className={style.page1}>
        <div className={style.navbar}>
          <div>
            <div>
              <img src={logo} alt="" />
            </div>
            <div>Home</div>
            <div>Feature</div>
            <div>
              <a href="#demo">Demo</a>
            </div>
            <div>Contact </div>
          </div>
          <div>
            <p>Login</p>
            <button>Sign up</button>
          </div>
        </div>
        <div className={style.page1MainContent}>
          <div>
            <div>
              <div>WhatsApp </div>
              Engagement <br />
              Platform
            </div>
            <div>
              Use WhatsApp Broadcasting on Chatvave <br />
              To Get 25x Growth in Sales and Revenue
            </div>
            <Formik
              initialValues={{ email: "" }}
              validationSchema={earlyAccessValidationSchema}
              onSubmit={handleEarlyAccessSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className={style.emailDiv}>
                    <div>
                      <input
                        type="email"
                        placeholder="Email"
                        name="email" // Add name attribute for Formik
                        value={values.email} // Use values.email instead of separate state
                        onChange={handleChange} // Use handleChange from Formik
                        onBlur={handleBlur} // Use handleBlur from Formik
                        className={
                          errors.email && touched.email ? style.errorInput : ""
                        }
                      />
                      <IoArrowForward />
                    </div>
                    {touched.email && errors.email && (
                      <div className={style.emailError}>{errors.email}</div>
                    )}
                    <div>
                      <button type="submit" className={style.submitButton}>
                        Get Early Access
                      </button>{" "}
                      {/* Add type="submit" to trigger form submission */}
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div className={style.page2}>
        <div>
          <div>
            Our <span> Features </span> you can get
          </div>
          <div>
          Automate WhatsApp conversations to manage your Facebook Ads leads with Chatvave WhatsApp CRM
          </div>
          <div>
            <div>
              <div>
                <div>
                  <h1>
                    Personalised broadcast <br />
                    campaigns
                  </h1>
                  <br />
                  <p>
                    Unlock the power of personalized communication with
                    WhatsApp's latest feature for businesses—the ability to
                    broadcast and automate promotional messages through the
                    WhatsApp Business API.
                    <br />
                    <br />
                    Now, you can seamlessly engage with your audience by
                    incorporating media and creating interactive messages
                    tailored to their preferences.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.p2multipleicons}>
          <div></div>
        </div>
      </div>
      <div className={style.page3}>
        <div>
          <div>
            <h1>Book your free demo</h1> <br />
            <p>
              Experience the power of Chatvave! Book your free demo now to
              discover how <br />
              we help you leverage WhatsApp for more efficient sales, marketing,
              and support.
            </p>
          </div>
          <div>
            <div></div>
            <div>
              <button>
                <a href="#demo">Try free demo</a>
                <FaChevronDown />
              </button>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div className={style.page4} id="demo">
        <div>
          <div>
            <Formik
              initialValues={{ name: "", email: "", message: "" }}
              validationSchema={demoValidationSchema}
              onSubmit={handleDemoSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form className={style.getinTouchDiv} onSubmit={handleSubmit}>
                  <h1>Book a Demo!</h1>
                  <br />
                  <label htmlFor="name">Name</label> <br />
                  <div className={style.gitinput}>
                    <RiUserSmileLine />
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Enter Your Name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {touched.name && errors.name && (
                    <div className={style.emailError}>{errors.name}</div>
                  )}
                  <label htmlFor="Email">Email</label> <br />
                  <div className={style.gitinput}>
                    <MdOutlineEmail />
                    <input
                      type="text"
                      id="Email"
                      name="email"
                      placeholder="Enter Your Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {touched.email && errors.email && (
                    <div className={style.emailError}>{errors.email}</div>
                  )}
                  <label htmlFor="Message">Message</label> <br />
                  <div className={style.gitinput2}>
                    <textarea
                      id="Message"
                      name="message"
                      placeholder="I own a business/service, would like to have chatvave for ..."
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {touched.message && errors.message && (
                    <div className={style.emailError}>{errors.message}</div>
                  )}
                  <div className={style.gitcheckbox}>
                    <input type="checkbox" name="terms" id="terms" />
                    <label htmlFor="terms">
                      I agree to <b onClick={gotoToTermsPage}>Privacy Policy</b>{" "}
                      and <b onClick={gotoToTermsPage}>Terms of Use</b>
                    </label>
                  </div>
                  <div className={style.gitbutton}>
                    <button type="submit">
                      {showbtnloder ? (
                        <div className={style.loader}></div>
                      ) : (
                        "Book Demo now"
                      )}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <footer className={style.footer}>
        <div className={style.alllinksdiv}>
          <div>
            <div>
              <img src={logo2} alt="" />
            </div>
            <div>
              <div>
                <FaYoutube />
              </div>
              <div>
                <FaFacebookF />
              </div>
              <div>
                <FaTwitter />
              </div>
              <div>
                <FaInstagram />
              </div>
              <div>
                <FaLinkedinIn />
              </div>
            </div>
          </div>
          <div>
            <div>Features</div>
            <div>Campaigns</div>
            <div>Bulk Messages</div>
          </div>
          <div>
            <div>Resource</div>
            <div>Help Center</div>
            <div>Blog</div>
            <div>Tutorials</div>
          </div>
          <div>
            <div>Support</div>
            <div>Contact Us</div>
            <div>FAQS</div>
            <div>Terms & Conditions</div>
            <div>Privacy Policy</div>
          </div>
          <div>
            <div>Company</div>
            <div>About</div>
            <div style={{ display: "flex", alignItems: "center" }}>
              Request Demo &nbsp;
              <FaArrowRight style={{ color: "rgba(225, 92, 18, 1)" }} />{" "}
            </div>
          </div>
        </div>
        <br />
        <br />
        <hr />
        <div>
          <div>chatvave @ {currentYear}. All rights reserved.</div>
          <div>
            <TbWorld /> EN
          </div>
        </div>
      </footer>

      {/* <.....................mobie view ......................> */}

      <div className={style.mobpage1}>
        <div className={style.mobnav}>
          <div>
            <img src={logo} alt="" />
          </div>
          <div>
            <RxHamburgerMenu />
          </div>
        </div>

        <div className={style.mobpage1Main}>
          <div>
            <h2>
              Automate conversational <br /> engagement on WhatsApp
            </h2>
            <br />
            <p>Build customised conversational chatbots for your Business.</p>

            <div>
              <button>Sign up</button>
            </div>
          </div>
          <div>
            <div>{/* <img src={mobpage1img} alt="" /> */}</div>
          </div>
        </div>
      </div>
      <div className={style.mobpage2}>
        <div>
          <div>
            <h2>
              Meet our premium features that will make you wow &nbsp;
              <img src={emoji} alt="" />
            </h2>
            <br />
            <p>
              Empower your team with the dynamic support of chatbots, seamlessly
              engaging customers side by side. Revolutionize your customer
              interactions with our innovative approach.
            </p>
          </div>
        </div>
        <div>
          <div className={style.p2headingdiv}>
            <small>Nurture enhanced customer connections instantly</small>
            <p>
              <br />
              Engage naturally with your customers and make sure your brand is
              customer-focused, attentive, and responsive at all times.
            </p>
          </div>
          <div className={style.p2imagediv}>
            <img src={mobpage2img} alt="" />
          </div>
          <div className={style.p2link1div}>
            Offer personalized product discovery
            <span>
              <IoArrowForward />
            </span>
          </div>
          <div className={style.p2link2div}>
            Empower customers through self-serve
            <span>
              <IoArrowForward />
            </span>
          </div>
        </div>
      </div>
      <div className={style.mobpage3}>
        <div>
          <div>
            <h2>
              ChatBot for Multiple <br />
              Use-Cases
            </h2>
            <br />
            <p>
              ChatQuiry is not just any chatbot; it's your comprehensive
              solution for crafting conversational chatbots embedded with
              intelligent capabilities.
            </p>
          </div>
          <div className={style.mobp3icondiv}>
            <div className={style.mob3icon}>
              <img src={icon1} alt="" />
              <h4>Lead Generation</h4>
              <small>Use gamified conversations to draw your users.</small>
            </div>
            <div className={style.mob3icon}>
              <img src={icon2} alt="" />
              <h4>Customer Support</h4>
              <small>Automate level 1 and level 2 support using SmatBot.</small>
            </div>
            <div className={style.mob3icon}>
              <img src={icon3} alt="" />
              <h4>Data Collection</h4>
              <small>Collect feedback, reviews and more from your users.</small>
            </div>
            <div className={style.mob3icon}>
              <img src={icon4} alt="" />
              <h4>Many More Features</h4>
              <h5>View More</h5>
            </div>
          </div>
        </div>
      </div>
      <div className={style.mobpage4}>
        <div>
          <h2>Get in touch!</h2>
          <p>We’d love to hear from you.</p>
          <div className={style.mobp4input}>
            <label htmlFor="fname">First Name</label>
            <input type="text" id="fname" placeholder="First Name" />
          </div>
          <div className={style.mobp4input}>
            <label htmlFor="Email">Email</label>
            <input type="email" id="Email" placeholder="Email" />
          </div>
          <div className={style.mobp4input2}>
            <label htmlFor="fname">First Name</label>
            <textarea type="text" placeholder="First Name" />
          </div>
          <div className={style.gitcheckbox}>
            <input type="checkbox" name="" id="mobterms" />

            <label htmlFor="mobterms">
              I agree to <b onClick={gotoToTermsPage}>Privacy Policy</b> and
              <b onClick={gotoToTermsPage}>Terms of Use</b>
            </label>
          </div>
          <div className={style.gitbutton}>
            <button>Send message</button>
          </div>
        </div>
      </div>
      <div className={style.mobfooterDiv}>
        <div className={style.mobsocilalink}>
          <div>
            <div>
              <img src={logo2} alt="" />
            </div>
            <div>
              <div>
                <FaYoutube />
              </div>
              <div>
                <FaFacebookF />
              </div>
              <div>
                <FaTwitter />
              </div>
              <div>
                <FaInstagram />
              </div>
              <div>
                <FaLinkedinIn />
              </div>
            </div>
          </div>
        </div>
        <div className={style.moballapgelink}>
          <div>
            <div>Features</div>
            <div>Campaigns</div>
            <div>Bulk Messages</div>
          </div>
          <div>
            <div>Resource</div>
            <div>Help Center</div>
            <div>Blog</div>
            <div>Tutorials</div>
          </div>
        </div>
        <div
          className={style.moballapgelink}
          style={{ gap: "2%", height: "40%" }}
        >
          <div>
            <div>Support</div>
            <div>Contact Us</div>
            <div>FAQS</div>
            <div>Terms & Conditions</div>
            <div>Privacy Policy</div>
          </div>
          <div>
            <div>Company</div>
            <div>About</div>
            <div style={{ display: "flex", alignItems: "center" }}>
              Sign in &nbsp;
              <FaArrowRight style={{ color: "rgba(225, 92, 18, 1)" }} />
            </div>
          </div>
        </div>

        <div>
          <div>chatvave @ {currentYear}. All rights reserved.</div>
          <div>
            <TbWorld /> EN
          </div>
        </div>
      </div>
    </>
  );
}

export default Landingpage;
