import Swal from "sweetalert2";
import style from "../../Pages/landingpage.module.css"
export const errorAlert = (message) => {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: message ? message : "Something went wrong!",
    showConfirmButton: false,
  timer: 2000,
  customClass: {
    popup: style.alertcss, 
  },
  });
};
export const sucessAlert = (message) => {
  Swal.fire({

    icon: "success",
    title: "success",
    text: message ? message : "success" ,
    showConfirmButton: false,
  
  timer: 2000,
  customClass: {
    popup: style.alertcss, 
  },
  });
};