import React from "react";

function TermsAndConditions() {
  return (
    <div style={styles.app}>
      <Header />
      <div style={styles.container}>
        <PrivacyPolicy />
        <TermsConditions />
      </div>
      <Footer />
    </div>
  );
}

const Header = () => (
  <header style={styles.header}>
    <h1>ChatVave </h1>
    <nav>
      <a href="#privacy-policy" style={styles.navLink}>
        Privacy Policy
      </a>
      <a href="#terms-conditions" style={styles.navLink}>
        Terms & Conditions
      </a>
    </nav>
  </header>
);

const PrivacyPolicy = () => (
  <section id="privacy-policy" style={styles.section}>
    <h2>Privacy Policy</h2>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac velit
      id metus convallis sollicitudin. Nullam at dui nulla. Curabitur vehicula
      erat ac dui scelerisque, non dignissim libero malesuada. Proin at metus at
      nunc scelerisque scelerisque non et nisl. Sed porttitor velit nec tellus
      tristique, et dignissim nunc suscipit.
    </p>
    <p>
      Phasellus sit amet orci vel lacus vestibulum commodo. Vestibulum at quam
      id magna pharetra convallis. Nullam non neque eu risus cursus
      sollicitudin. Suspendisse potenti. Integer auctor ex a libero facilisis
      varius. Proin id volutpat felis. Nunc sit amet urna ut lacus vestibulum
      tristique at ac risus.
    </p>
  </section>
);

const TermsConditions = () => (
  <section id="terms-conditions" style={styles.section}>
    <h2>Terms and Conditions</h2>
    <p>
      Integer auctor magna eu urna dictum, ut luctus ipsum euismod. Vestibulum
      aliquet, quam nec vulputate suscipit, nulla sem vulputate elit, non
      interdum velit nisl non odio. Vivamus dignissim tincidunt velit, ut
      gravida magna pharetra vel. Fusce sed leo lectus. Vivamus hendrerit ipsum
      ac nulla dapibus bibendum.
    </p>
    <p>
      Praesent sodales risus vitae turpis vulputate, a luctus quam malesuada.
      Curabitur vulputate, nisl sit amet dictum placerat, justo metus lacinia
      nunc, a aliquam nulla nisl at ex. Donec dignissim semper turpis, ac
      facilisis orci volutpat ac. Nulla facilisi. Quisque at pharetra ante, vel
      blandit lorem.
    </p>
  </section>
);

const Footer = () => (
  <footer style={styles.footer}>
    <p>&copy; 2024 ChatVave. All rights reserved.</p>
  </footer>
);

const styles = {
  app: {
    fontFamily: "Arial, sans-serif",
    lineHeight: 1.6,
    margin: 0,
    padding: 0,
  },
  header: {
    backgroundColor: "#333",
    color: "#fff",
    padding: "10px 0",
    textAlign: "center",
  },
  navLink: {
    color: "#fff",
    textDecoration: "none",
    margin: "0 10px",
  },
  container: {
    padding: "20px",
  },
  section: {
    marginBottom: "20px",
  },
  footer: {
    backgroundColor: "#333",
    color: "#fff",
    padding: "10px 0",
    textAlign: "center",
    position: "fixed",
    width: "100%",
    bottom: 0,
  },
};

export default TermsAndConditions;
