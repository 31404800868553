import axios from 'axios';


const BrevoHeaders = {
    "accept": "application/json",
  "Content-Type": "application/json",
  "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY, // Use environment variable for API key
};

class Utils {


  addBrevoEarlyAccessList = async (email) => {
    console.log("email-->", email);
    try {
      const response = await axios.post(
        'https://api.sendinblue.com/v3/contacts',
        {
          email: email,
          listIds: [4],
          updateEnabled: true,
        },
        {
          headers: BrevoHeaders,
        }
      );
      console.log('API called successfully. Returned data: ' + JSON.stringify(response.data));
      return { status: true, ack: "API called successfully"}
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      return { status: false, ack: "something went wrong", error: error.response ? error.response.data : error.message}
    }
  }

  addBrevoDemoBookList = async (email, name, message) => {
    console.log("email, name, message",email, name, message);
    // debugger
    try {
      const response = await axios.post(
        'https://api.sendinblue.com/v3/contacts',
        {
          email: email,
          attributes: {FULL_NAME: name.toString(), MESSAGE: message.toString()},
          listIds: [7],
          updateEnabled: true,
        },
        {
          headers: BrevoHeaders,
        }
      );
      console.log('API called successfully. Returned data: ' + JSON.stringify(response.data));
      return { status: true, ack: "API called successfully"}
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      return { status: false, ack: "something went wrong", error: error.response ? error.response.data : error.message}
    }
  }
}

const utilsInstance = new Utils();
export default utilsInstance;
